import React from 'react';
import './css/common.css';
import './App.css';
import Sample from './Component/Sample/Sample';
import { Icon } from 'antd';


function App() {
  return (
    <div className="ccFlexColumn" style={{height:"100vh",width:"100vw",background:"#efefef"}}>
      <div className="ccFlexColumn" style={{borderRadius:"10px",width:"80%",height:"80%",background:"white",marginBottom:"10px"}}>
        <h2>暂时没空写主页，有空的可以关注我的各种社交账号及博客</h2>
        <p>QQ:1647075274</p>
        <p>WX:hanchanqiu</p>
        <p>BILIBILi:游鱼星</p>
        <p>github:zjufishboy</p>
        <p>微博:游鱼星</p>
        <p>博客:<a href="http://blog.fishstar.xyz">游鱼星的博客</a></p>
        
      </div>
      <a href="http://www.beian.miit.gov.cn/" style={{textDecoration:"none",color:"#222"}}>浙ICP备19002880号</a>
    </div>
  );
}

export default App;
